<template>
  <div>
    <h2 class="text-lg leading-6 font-medium text-gray-900">Overview</h2>
    <!-- Card -->
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <div class="
        relative
        bg-white
        pt-5
        px-4
        pb-12
        sm:pt-6 sm:px-6
        shadow
        rounded-lg
        overflow-hidden
      ">
        <dt>
          <div class="absolute bg-blue-500 rounded-md p-3">
            <ScaleIcon
              class="h-6 w-6 text-white"
              aria-hidden="true"
            />
          </div>
          <p class="ml-16 text-sm font-medium text-gray-500 truncate">
            Total Cases
          </p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ dashboard.totalCases }}
          </p>
          <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm">
              <router-link
                :to="{ name: 'cases' }"
                class="font-medium text-blue-600 hover:text-blue-500"
              >
                View Cases -></router-link>
            </div>
          </div>
        </dd>
      </div>
      <div class="
        relative
        bg-white
        pt-5
        px-4
        pb-12
        sm:pt-6 sm:px-6
        shadow
        rounded-lg
        overflow-hidden
      ">
        <dt>
          <div class="absolute bg-blue-500 rounded-md p-3">
            <ScaleIcon
              class="h-6 w-6 text-white"
              aria-hidden="true"
            />
          </div>
          <p class="ml-16 text-sm font-medium text-gray-500 truncate">
            Cases this month
          </p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ dashboard.totalMountlyCases }}
          </p>
          <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm">
              <router-link
                :to="{ name: 'cases' }"
                class="font-medium text-blue-600 hover:text-blue-500"
              >
                View Cases -></router-link>
            </div>
          </div>
        </dd>
      </div>
      <div class="
        relative
        bg-white
        pt-5
        px-4
        pb-12
        sm:pt-6 sm:px-6
        shadow
        rounded-lg
        overflow-hidden
      ">
        <dt>
          <div class="absolute bg-blue-500 rounded-md p-3">
            <CreditCardIcon
              class="h-6 w-6 text-white"
              aria-hidden="true"
            />
          </div>
          <p class="ml-16 text-sm font-medium text-gray-500 truncate">Payments</p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">£13.567</p>
          <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm">
              <a
                href="https://dashboard.stripe.com/dashboard"
                target="_blank"
                rel="noopener"
                class="font-medium text-blue-600 hover:text-blue-500"
              >
                Stripe dashboard ↗️</a>
            </div>
          </div>
        </dd>
      </div>
    </dl>

    <div class="grid gap-5">
      <div class="lg:col-span-2 md:col-span-2 min-w-full">
        <h2 class="mx-auto mt-8 text-lg leading-6 font-medium text-gray-900">
          Recent case updates
        </h2>
        <div
          v-if="dashboard.totalCases"
          class="flex flex-col mt-4"
        >
          <div class="py-2 align-middle inline-block max-w-full">
            <div class="
              shadow
              overflow-auto
              lg:overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
            ">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                    >
                      Update
                      </th>
                      <th
                        scope="col"
                        class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                      >
                        Case
                        </th>
                        <th
                          scope="col"
                          class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                        >
                          Client
                          </th>
                  </tr>
                </thead>
                <tbody class="bg-white">
                  <tr
                    :class="i % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    v-for="(item, i) in updates.items"
                    :key="item.id"
                  >
                    <td class="px-6 py-4">
                      <p class="text-gray-900 text-sm font-medium">
                        {{ formatUpdateType(item) }}
                      </p>
                      <p class="text-gray-500 text-sm">
                        {{ DateTime.fromISO(item.created_at).toLocaleString( DateTime.DATETIME_MED ) }}
                      </p>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        <div class="flex items-center">
                          <router-link
                            :to="{
                            name: 'case',
                            params: { id: item.case.id },
                          }"
                            :class="
                            item.case?.info.app_type.uid === 'sale'
                              ? 'bg-green-100 text-green-800'
                              : 'bg-red-100 text-red-800'
                          "
                            class="
                            px-2
                            flex
                            text-xs
                            leading-5
                            font-semibold
                            rounded-full
                          "
                          >
                            <span
                              :title="
                              item.case?.info.app_type.name +
                              ' of ' +
                              item.case?.info.address.street_address
                            "
                              class="truncate max-w-sm"
                            >
                              {{ item.case?.info.app_type.name }} of {{ item.case?.info.address.street_address }}
                              </span>
                              </router-link>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <span class="flex-grow">
                        <router-link
                          :to="{
                          name: 'client',
                          params: { id: item.case.user.id },
                        }"
                          class="flex-shrink-0 group block"
                        >
                          <div class="flex items-center">
                            <Avatar
                              :className="[
                              'inline-block',
                              'h-9',
                              'w-9',
                              'rounded-full',
                            ]"
                              :user="item.case.user"
                            />
                            <div class="ml-3">
                              <p class="
                                text-sm text-gray-800
                                group-hover:text-gray-900
                                font-medium
                              ">
                                {{ item.case.user.first_name }} {{ item.case.user.last_name }}
                              </p>
                              <p class="
                                text-xs
                                font-medium
                                text-gray-700
                                group-hover:text-gray-900
                              ">
                                View profile ->
                              </p>
                            </div>
                          </div>
                          </router-link>
                      </span>
                    </td>
                    </tr>
                </tbody>
              </table>
            </div>
            <Pagination
              @page-changed="handlePageChanged"
              :total="updates.count"
              :current_page="current_page"
            />
          </div>
      </div>
      <div
        class="flex flex-col mt-4 bg-gray-50 px-4 py-4 sm:px-6"
        v-else
      >
        There is no completed action yet
    </div>
  </div>
  </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import Pagination from "@/components/Pagination.vue";
import { useStore } from "vuex";
import { ScaleIcon, CreditCardIcon } from "@heroicons/vue/solid";
import { DateTime } from "luxon";
import Avatar from "../../components/Avatar.vue";

export default {
  components: {
    CreditCardIcon,
    ScaleIcon,
    Pagination,
    Avatar
  },
  setup() {
    const current_page = ref(1);
    const store = useStore();

    const handlePageChanged = async page => {
      await store.dispatch("action/getRecentUpdates", page);
      current_page.value = page;
    };

    const dashboard = computed(() => store.state.cases.dashboard);

    onMounted(async () => {
      store.dispatch("action/getRecentUpdates");
      if (dashboard.value.totalCases) return;
      store.dispatch("cases/getDashboardValues");
    });

    const formatUpdateType = update => {
      switch (update.update_type.uid) {
        case "sign":
          return "Document Signed";

        case "payment":
          return "Payment made";
      }
    };

    return {
      handlePageChanged,
      current_page,
      DateTime,
      formatUpdateType,
      dashboard,
      updates: computed(() => store.state.action.recent_updates)
    };
  }
};
</script>